<template>
  <div>
    <c-table
      title="아차사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      @linkClick="linkClick"
    >
      <template slot="suffixTitle">
        <font class="text-negative" style="font-size:0.9em;font-weight:500;">
          ※ 아차사고는 작성월 기준 전월 데이터가 자동 조회됩니다. (직접 입력은 제외)
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>  
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "nearAccident",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopEnvSafKpiId: '',
      }),
    },
    target: {
      type: Object,
      default: () => ({
        sopEnvSafKpiId: '',
        plantCd: '',
        targetMonth: '',
        prevMonth: '',
        envSafStatusCd: '',
        targetTitle: '',
        writeDeptCd: '',
        regUserId: '',
        chgUserId: '',
        targetList: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentNo",
            field: "iimNearAccidentNo",
            label: "아차사고번호",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearAccidentName",
            field: "iimNearAccidentName",
            label: "아차사고명",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            label: "공정",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "iimStatusNearName",
            field: "iimStatusNearName",
            style: 'width:100px',
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "iimNearKindName",
            field: "iimNearKindName",
            style: 'width:120px',
            label: "재해종류",
            align: "left",
            sortable: true,
          },
          {
            name: "accidentUser",
            field: "accidentUser",
            label: '재해자 정보',
            child: [
              {
                name: "accidentDeptName",
                field: "accidentDeptName",
                style: 'width:80px',
                label: "부서",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentUserName",
                field: "accidentUserName",
                style: 'width:80px',
                label: "이름",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentEmpNo",
                field: "accidentEmpNo",
                style: 'width:80px',
                label: "사번",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentAge",
                field: "accidentAge",
                style: 'width:80px',
                label: "연령",
                align: "center",
                sortable: true,
              },
              {
                name: "accidentLongevityCount",
                field: "accidentLongevityCount",
                style: 'width:80px',
                label: "근속년수",
                align: "center",
                sortable: true,
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        iimGradeCd: null,
        iimNearKindCds: [],
        iimStatusNearCd: null,
        processCd: '',
        period: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.target.envSafStatusCd === 'ESK0000005'
        // 결재중인 경우 비활성화
        || this.target.approvalStatusCd === 'ASC0000001'
    },
  },
  watch: {
    'target.prevMonth'() {
      this.getList();  
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.iim.accident.near.list.url;
      // code setting
      // list setting 
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        prevMonth: this.target.prevMonth,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    nearAccidentRegister() {
      this.linkClick();
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "아차사고 상세"; // 아차사고 상세
      this.popupOptions.param = {
        iimNearAccidentId: row ? row.iimNearAccidentId : '',
        iimStatusNearCd: row ? row.iimStatusNearCd : '', //아차사고 진행상태
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/iim/near/nearAccidentDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    }, 
  },
};
</script>