var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          attrs: {
            title: "아차사고 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            filtering: false,
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "suffixTitle" },
            [
              _c(
                "font",
                {
                  staticClass: "text-negative",
                  staticStyle: { "font-size": "0.9em", "font-weight": "500" },
                },
                [
                  _vm._v(
                    " ※ 아차사고는 작성월 기준 전월 데이터가 자동 조회됩니다. (직접 입력은 제외) "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }